// page
.cr-page {
    min-height: 100vh;
    &__header {
        @include media-breakpoint-down(sm) {
            flex-direction: column;
            align-items: flex-start;
        }
        display: flex;
        align-items: flex-end;
        align-self: center;
    }

    &__title {
        @include media-breakpoint-down(sm) {
            margin-bottom: 0;
        }
        padding-right: 0.5rem;
        margin-bottom: 1rem;
    }

    &__breadcrumb {
        @include media-breakpoint-down(sm) {
            align-self: flex-start;
        }
        align-self: flex-end;
        padding: 0;
        text-transform: capitalize;
        background: theme-color("light");
    }

    .row {
        // stylelint-disable-next-line
        .col,
        > [class^="col-"] {
            margin-bottom: 1rem;
        }
    }
}

label {
    text-align: left;
    display: block;
}

.cr-page-spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pl-10 {
    padding-left: 10%;
}

.ml-3 {
    margin-left: 3%;
}

.App {
    height: 100vh;
}

.dashbord-container {
    flex-grow: 1;
    padding: 24px;
}

.table-view {
    padding-bottom: 1rem;
}

.master-table-view {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rookie-footer {
    position: fixed;
    width: 100%;
    bottom: 0%;
    font-size: 10px;
    cursor: pointer;
    background: transparent;
    opacity: 0.86;
    display: flex;
    justify-content: flex-end;
}

.wrapper-class {
    padding: 5px;
    border: 1px solid #bbbbbb;
    border-radius: 8px;
    opacity: 0.7;
}
.editor-class {
    background-color: theme-color(light);
    padding: 0% 2% 0% 2%;
    border: 1px solid #bbbbbb;
    border-radius: 8px;
    min-height: 150px;
}
.toolbar-class {
    border: 1px solid #bbbbbb;
    border-radius: 8px;
}

.disabled,
[disabled] {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.65;
    box-shadow: none;
}

.visible {
    visibility: visible;
}

.hidden {
    visibility: hidden;
}

.activity-log-view {
    height: 80vh;
    overflow-y: auto;
}

.activity-log-view::-webkit-scrollbar {
    width: 12px;
}

.tag-editor {
    display: flex;
    border: 1px solid #ced4da;
    // border-radius: 4%;
    padding: 1%;
    overflow-x: auto;
    margin-bottom: 2%;
}

.tag {
    display: flex;
}

.tag input {
    width: auto !important;
    background-color: #cee7f9;
    border: unset;
}

.tag a {
    cursor: pointer;
}

.stars-section div {
    justify-content: center;
}

.js-plotly-plot {
    display: unset !important;
    // margin-top: 2%;
}

.js-plotly-plot .plotly,
.js-plotly-plot .plotly div {
    margin: 0% 4% 4% 4% !important;
}

.js-plotly-plot .plotly .modebar {
    display: flex !important;
    top: unset !important;
}

.svg-container .main-svg {
    border: 1px solid #c4c2c2;
    border-radius: 8px;
    box-shadow: 10px 10px 20px #dbdada;
}

.ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #fefcfc;
    min-height: 100px;
}

/* Snow Theme */
.ql-snow.ql-toolbar {
    display: flex;
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
}

.overlay {
    height: 100vh;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgb(255, 255, 255);
    background-color: rgba(255, 255, 255, 0.4);
    overflow-x: hidden;
    display: flex;
    justify-content: flex-end;
}

.overlay-content {
    position: relative;
    top: 40%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
}
.scroll {
    overflow-x: auto;
}
.scroll::-webkit-scrollbar {
    height: 8px;
}
.scroll::-webkit-scrollbar-track {
    box-shadow: inset;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
}
.scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    // background: hsl(24, 100%, 50%);
    background: hsl(204, 8%, 76%);
}
.scrollbar-hidden::-webkit-scrollbar {
    display: none;
}
.scanOverlay {
    top: 0px;
    left: 0px;
    z-index: 1;
    box-sizing: border-box;
    border: 50px solid transparent;
    position: absolute;
    width: 100%;
    height: 100%;
}
