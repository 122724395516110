.cr-content {
  // @include media-breakpoint-up(lg) {
  //   padding: 0 0 0 224px;
  // }

  // @include media-breakpoint-down(md) {
  //   &::before {
  //     content:"";
  //     display: block;
  //     height: 100%;
  //     position: absolute;
  //     top: 0;
  //     left: 0;
  //     width: 100%;
  //     background-color: rgba(255, 255, 255, .8);
  //     z-index: 1;
  //   }
  // }

  // @include media-breakpoint-down(md) {
  //   padding: 0 0 0 68px;
  // }

  // @include media-breakpoint-down(xs) {
  //   padding: 0;
  // }

  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  min-height: 100vh;
  padding: 0;
  margin: 0;
}
.edit-icon-container {
  position: absolute;
  right: 0;
  top: 0;
}
.main-page-layout {
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.position-text {
  position: relative;
}

.cross-mark-icon {
  position: absolute;
  right: 0;
  top: 0;
}

.w-100 {
  width: 100%;
}

.modal-backdrop {
  background-color: #0000004d;
}

.workflow-template {
  position: absolute;
  left: 0;
  top: 11%;
  background: rgba(56, 182, 255, 0.16);
  z-index: 111;
  height: 100vh;
  width: 25vw;
}

.fixed-section {
  width: 100%;
  height: 30px;
  position: fixed;
  bottom: 4%;
}

.fixed-section-top {
  width: 100%;
  height: 25px;
  position: fixed;
  top: 8%;
}

.header-section {
  position: fixed;
  width: 100%;
  /* background: #111; */
  z-index: 111;
}

.project-title {
  background: none;
  position: absolute;
  left: 4%;
  top: 14%;
  color: #808080;
}

.icon-placement {
  padding-right: 3%;
  padding-top: 1%;
}

.node-container {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 14%;
  padding-bottom: 14%;
  border-radius: 10px;
}

.workflow-error {
  color: red;
  text-align: left;
  position: absolute;
  left: 15%;
  top: 35%;
}

.date-container {
  padding: 0 5%;
}

.human-interaction-container {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 3%;
}

.no-users-added {
  position: absolute;
  top: 3%;
  right: 5%;
  color: red;
}

.users-added {
  position: absolute;
  top: 3%;
  left: 5%;
}
.onboarding-selection-container {
  display: flex;
  margin-left: 1%;
  margin-bottom: 2%;
  justify-content: space-between;
}

.copy-icon {
  position: absolute;
  top: 18%;
  right: 1%;
  font-size: 22px;
  cursor: pointer;
}

.copy-text {
  position: absolute;
  top: 18%;
  right: 4%;
  color: green;
  cursor: pointer;
}

.workflow-container {
  display: flex;
  justify-content: space-between;
}

.workflow-container p,
label {
  margin: 0;
  padding: 0;
}

.regenerate-link {
  cursor: pointer;
}

.switch-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 3%;
}

.switch-container label {
  margin-right: 3%;
}

.date-node-container {
  // background: #f8f9fa;
  /* margin-bottom: 8%; */
  // padding-bottom: 2%;
  padding-top: 2%;
}

.date-node-container .react-datepicker-wrapper {
  width: 100%;
  padding-top: 2%;
}

.date-node-container input {
  width: 100%;
  border: 1px solid #ced4da;
}

.action-modal {
  max-width: 70%;
}
.edit-container {
  position: absolute;
  top: 0;
  right: 0;
}

.basic-multi-select {
  font-size: 0.75rem !important;
  line-height: 1.789;
  border-radius: 0.5rem !important;
  font-family: "Poppins", "Roboto", "Helvetica", "Arial", sans-serif;
}

.remove-icon-container {
  position: absolute;
  right: 1%;
  top: -1%;
}
// .date-meeting-node-container {
//   max-width: 80%;
// }
.button-container {
  position: absolute;
  left: 21%;
  top: 4%;
}
.mt-1 {
  margin-top: 1rem;
}

.success-response {
  padding-left: 1rem;
  font-size: 1rem;
  padding-top: 0.3rem;
  color: #4ecb4e;
}
