.error-container {
    width: 75%;
    max-width: 700px;
    margin: 1.5rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}
@media (max-width: 650px) {
    .error-container {
        width: 85%;
    }
}
.error-container .header {
    color: #c8bdba;
    font-size: 3em;
    font-weight: 700;
    text-align: center;
    text-shadow: 2px 2px 5px #727272;
}
@media (max-width: 650px) {
    .error-container .header {
        font-size: 3em;
    }
}

.compcontainer {
    width: 75%;
    height: 13rem;
    padding: 1rem 0;
}
@media (max-width: 650px) {
    .compcontainer {
        height: 10rem;
    }
}
.compcontainer svg {
    max-width: 100%;
    max-height: 100%;
    animation: bouncy 1300ms linear infinite;
}

.instructions {
    background: #fefefe;
    /* width: 80%; */
    height: auto;
    padding: 2rem 4rem;
    margin: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #dcdcdc;
    border-radius: 0.25rem;
}
@media (max-width: 650px) {
    .instructions {
        width: 100%;
    }
}

@media (max-width: 650px) {
    .instructions h2 {
        font-size: 1.05em;
    }
}
.instructions p {
    /* font-size: 0.8em; */
    line-height: 1.5;
    color: #122125;
}
@media (max-width: 650px) {
    .instructions p {
        font-size: 1em;
    }
}
.instructions .step {
    display: flex;
    width: 100%;
    margin: 1em 0;
    justify-content: center;
}
.instructions .step .icon {
    width: 1.25rem;
    height: 1.25rem;
    align-self: center;
}
@media (max-width: 650px) {
    .instructions .step .icon {
        width: 1rem;
        height: 1rem;
    }
}
.instructions .step p {
    display: inline-block;
    width: 80%;
    line-height: 1.5;
    padding-left: 0.5rem;
}

@keyframes bouncy {
    0% {
        transform: translateY(10px) translateX(0) rotate(0);
    }
    25% {
        transform: translateX(-10px) rotate(-10deg);
    }
    50% {
        transform: translateX(0) rotate(0deg);
    }
    75% {
        transform: translateX(10px) rotate(10deg);
    }
    100% {
        transform: translateY(10px) translateX(0) rotate(0);
    }
}
