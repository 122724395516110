.nav-item {
    .nav-link {
        display: flex;
        align-items: center;
        color: #000000;

        &.active {
            // color: theme-color(secondary);
            // background: rgba(255, 255, 255, .23);
            opacity: 1;
        }
    }
}

.login {
    .nav-item {
        .nav-link {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &.active {
                // color: theme-color(secondary);
                // background: rgba(54, 73, 153, 0.5);
                opacity: 1;
                cursor: pointer;
            }
        }
    }
}

.sidebar .nav a {
    transition: all 150ms ease-in;
}

.card-header {
    text-transform: uppercase;
}

.badge:empty {
    display: inline-block;
}

.custom-file {
    &-label {
        color: #abb2be;
        font-size: 0.875rem;
        padding: 0.5rem 0.75rem;
        height: calc(1.5em + 1rem + 2px);
        &::after {
            padding: 0.5rem 0.75rem;
            height: calc(1.5em + 1rem);
        }
    }
}
