@import url(https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i);
// @import url(https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i);

/* Font Smoothing */

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
td,
button,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.display-1,
.display-2,
.display-3,
.display-4,
.navbar,
.brand,
.alert {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  // font-family: Poppins, Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: $font-weight-normal;
}

.cr-app {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

.cr-app-overflow {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

.fs-1 {
  font-size: 0.7rem;
}

.fs-2 {
  font-size: 1rem;
}

.m-top1 {
  margin-top: 0.65rem;
}
.submenu-parent {
  position: relative;
}
.submenu-icon {
  position: absolute;
  left: 6%;
  top: 10%;
}

.submenu-section {
  // display: -webkit-inline-box;
  // display: -webkit-inline-flex;
  // display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  /* position: relative; */
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: "Poppins", "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  // line-height: 1.625;
  letter-spacing: 0.00938em;
  // display: -webkit-box;
  // display: -webkit-flex;
  // display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -webkit-text-decoration: none;
  text-decoration: none;
  min-height: 48px;
  padding-top: 6px;
  padding-bottom: 6px;
  box-sizing: border-box;
  white-space: nowrap;
  padding-left: 16px;
  padding-right: 16px;
  min-width: 10rem;
  min-height: unset;
  padding: 0.3rem 1rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  color: #67748e;
  -webkit-transition: background-color 300ms ease, color 300ms ease;
  transition: background-color 300ms ease, color 300ms ease;
}
