@import "variables";

// libraries
// TODO: Loose bootstrap styling
@import "~bootstrap/scss/bootstrap.scss";

// base
@import "base";
@import "overrides";
@import "extends";
@import "animations";
@import "utils/utils";

// components
@import "components/header";
@import "components/sidebar";
@import "components/content";
@import "components/page";
@import "components/widget";
@import "components/search-input";
@import "components/paginate";

// themes
@import "themes/colors";

// demos (only for demo pages)
@import "demos/demos";
