@import "./tailwind-main.css";

.App {
    /* text-align: center; */
    background-color: #f8f9fa;
    margin: 0;
    font-family: "Poppins", "Roboto", "Helvetica", "Arial", sans-serif !important;
}
.poppins-font {
    font-family: "Poppins";
  }
.know-more-container {
    background: linear-gradient(166deg, white, #ffffff82);
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 111;
    top: 0;
    border-radius: 9px;
    display: flex;
    text-align: center;
    justify-content: center;
}

.know-more-button {
    background-color: rgb(255, 87, 51);
    align-self: center;
    /* width: 100%; */
    margin: 0 2rem;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(103, 116, 142);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(103, 116, 142);
}

.custom-scrollbar::-webkit-scrollbar {
    width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    height: 5%;
    
}

.custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #e3e2e2 transparent;
    height: 2px;
}
.dangerous-html p {
    margin-bottom: 0;
}
.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.custom-modal-styles {
    max-width: 60% !important;
}

.custom-modal-styles .modal-body {
    height: 450px;
    overflow: auto;
}
body {
    overflow-x: hidden !important;
}

.form-control:focus {
    border-color: rgb(53, 209, 245) !important;
    outline: 0;
    box-shadow: 0 0 0.2rem rgb(129 227 249) !important;
}

.form-control {
    font-size: 0.875rem !important;
    height: 2.5rem !important;
    border-radius: 0.5rem !important;
    font-family: "Poppins", "Roboto", "Helvetica", "Arial", sans-serif;
}

.timer-wrapper {
    display: flex;
    justify-content: flex-end;
    top: 30%;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.ai-button {
    background-color: #9f1bef !important;
}

.ai-button-outline {
    color: #9f1bef !important;
    border-color: #9f1bef !important;
}

.result-button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
}

.custom-css-button {
    border: none;
    background: green;
    padding: 0.5rem 1rem;
    color: #fff;
    border-radius: 8px;
    padding-bottom: 0.7rem;
}
.custom-card-container {
    box-shadow: 0px 3px 6px 5px rgb(0 0 0 / 6%);
    padding: 1rem;
}

.card-container {
    box-shadow: 0rem 1.25rem 1.6875rem 0rem rgba(0, 0, 0, 0.05);
    padding: 1rem;
    background: #fff;
    border-radius: 12px;
}

.mx-wdth {
    max-width: 47% !important;
}

.info-icon-container {
    position: absolute;
    right: 2%;
    top: 0;
    font-size: 1rem;
}

.cursor-pointer {
    cursor: pointer;
}

.anchor-button {
    font-size: 13px;
    outline: none;
    border: none;
    background: none;
    color: #7b7272;
}

.plain-button {
    outline: none;
    border: none;
    background: none;
    color: #7b7272;
}
.add-button-icon {
    background: #fd5e3c;
    outline: none;
    border: none;
    color: #fff;
    padding: 3% 14%;
    border-radius: 7px;
    margin-top: 0.3rem;
}
.pt-10 {
    padding-top: 10px !important;
}

.color-white {
    color: #fff !important;
}

.custom-width {
    max-width: 97% !important;
}

.tabs-container button:focus {
    outline: none;
}
.tests-display-container {
    height: 400px;
    overflow: auto;
}

.accordian-card-container {
    background-color: #fff;
    color: rgb(52, 71, 103);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow: hidden;
    word-wrap: break-word;
    background-color: #ffffff;
    -webkit-background-clip: border-box;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, 0.125);
    border-radius: 1rem;
    box-shadow: 0rem 1.25rem 1.6875rem 0rem rgba(0, 0, 0, 0.05);
    margin-right: 1rem;
    margin-top: 1rem;
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
}

.accordion-card-container {
    background-color: #fff;
    color: rgb(52, 71, 103);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    /* margin-right: 1rem; */
    overflow: hidden;
    word-wrap: break-word;
    background-color: #ffffff;
    -webkit-background-clip: border-box;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, 0.125);
    border-radius: 1rem;
    box-shadow: 0rem 1.25rem 1.6875rem 0rem rgba(0, 0, 0, 0.05);
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
}

.reco-accordion-card-container {
    background-color: #f8f8f8;
    overflow: hidden;
    word-wrap: break-word;
    -webkit-background-clip: border-box;
    background-clip: border-box;
    border-radius: 0.5rem !important;
    box-shadow: none;
}

.accordian-card-container ::before {
    background-color: rgb(255 255 255 / 12%) !important;
}
.company-card-container {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-start;
    padding: 24px;
    flex-direction: column;
    margin-top: 16px;
    opacity: 1;
    background: rgb(248, 249, 250);
    color: rgb(52, 71, 103);
    border-radius: 0.75rem;
}

.list-container {
    --icon-space: 1.3em;
    list-style: none;
    padding: 0;
}

.revoke-button {
    color: #fff;
    padding: 0;
}

.center-modal-container {
    top: 16%;
}

.list-container li {
    padding-left: var(--icon-space);
    font-size: medium;
}

.list-container li:before {
    content: "\f00c"; /* FontAwesome Unicode */
    font-family: FontAwesome;
    color: greenyellow;
    display: inline-block;
    margin-left: calc(var(--icon-space) * -1);
    width: var(--icon-space);
}

.cancel-icon-container {
    position: absolute;
    top: 0.5rem;
    font-size: 1.5rem;
    right: 1rem;
    cursor: pointer;
    z-index: 11;
}
.custom-border {
    border-bottom: 1px solid #ccc;
    width: 70%;
    text-align: center;
    position: absolute;
    display: block;
    /* padding-left: 5rem; */
    left: 16%;
}
.p-relative {
    position: relative;
}

.mrg-r-2 {
    margin-right: 2rem;
}

.mrg-r-1 {
    margin-right: 1.4rem;
}
.mrg-l-1 {
    margin-left: 1rem;
}
.badge-container {
    /* position: absolute; */
    background-image: linear-gradient(
        310deg,
        rgb(234, 6, 6),
        rgb(255, 102, 124)
    );
    /* top: -1.2rem;
  left: 1rem; */
    font-size: 0.6rem;
    color: #fff;
    padding: 0 1rem;
    border-radius: 5px;
}

.coming-soon-container {
    /* position: absolute; */
    background-image: linear-gradient(310deg, rgb(255 88 46), rgb(255 91 91));
    /* top: -1.2rem;
  left: 1rem; */
    font-size: 0.6rem;
    color: #fff;
    padding: 0 1rem;
    border-radius: 5px;
}
.unread-message-badge-container {
    position: absolute;
    background-image: linear-gradient(
        310deg,
        rgb(234, 6, 6),
        rgb(255, 102, 124)
    );
    top: 0.5rem;
    font-size: 0.6rem;
    right: 1.4rem;
    color: #fff;
    padding: 0 0.4rem;
    border-radius: 5px;
}
.badge-container-text {
    background-image: linear-gradient(
        310deg,
        rgb(255 87 51),
        rgb(248, 145, 122)
    );
    font-size: 0.6rem;
    font-weight: bold;
    color: #fff;
    padding: 0.125rem 0.6rem;
    border-radius: 5px;
    line-height: 1.375;
}

.custom-css-button:hover {
    transform: scale(1.05);
    transition: transform 200ms;
}

.general-layout-modal .modal-content {
    border: none !important;
    border-radius: 15px !important;
}
.enter-key-container {
    position: absolute;
    top: 0;
    right: 0.5rem;
    cursor: pointer;
}

.enter-key-container p {
    font-size: 0.625rem;
    margin: 0;
    padding: 0;
    margin-left: 0.2rem;
    margin-top: -5px;
}
.overflow-ellipsis {
    width: 80%; /* Preferred width of your requirement */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.App-link {
    color: #61dafb;
}

.custom-file {
    font-size: 1.1rem;
}

.custom-checkbox {
    line-height: 1;
}

.c {
    margin-top: 1.9rem !important;
}

.modal {
    top: 3rem !important;
}
.spinner-component-container {
    position: fixed;
    height: 100vh;
    top: 0;
    width: 100%;
    background: #ffffff85;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}
@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.react-stars-component {
    margin-top: 6px;
}

.custom-file-label {
    font-size: 0.9rem;
}

.danger-color {
    color: #ff0000cc;
}

.swal2-title {
    font-family: "Poppins", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1.75rem;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: 0.03333em;
}
.swal2-html-container {
    font-family: "Poppins", "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: 0.03333em;
}

.swal2-confirm {
    font-family: "Poppins", "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: 0.03333em;
}

.spinner-box-container {
    background: #ffffff91;
    width: 93%;
    height: 100%;
    position: absolute;
    z-index: 11111111;
    display: flex;
    flex-direction: row;
}

.spinner-box-container div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.spinner-box-container span {
    font-size: 1rem;
    margin-left: 1rem;
    color: #797777;
}

.read-class {
    background: #cbcbcb6b;
}

.team-scroll-container ::-webkit-scrollbar {
    width: 3px;
    height: 6px;
}

/* Track */
.team-scroll-container ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
.team-scroll-container ::-webkit-scrollbar-thumb {
    background: rgb(167, 167, 168);
    border-radius: 10px;
}

.tests-container p {
    font-family: "Poppins", "Roboto", "Helvetica", "Arial", sans-serif;
    color: rgb(52, 71, 103);
    font-weight: 700;
}

.tests-container ul {
    font-family: "Poppins", "Roboto", "Helvetica", "Arial", sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    color: rgb(52, 71, 103);
}

.coming-soon-text {
    /* position: absolute; */
    font-size: 11px;
    /* top: -2.3rem;
  right: -1.1rem; */
    color: orange;
    width: 100%;
}

.badge-display-container {
    position: absolute;
    bottom: 1rem;
}

.dots {
    font-weight: bold;
    display: flex;
    align-items: center;
    font-size: 16px;
    clip-path: inset(0 1ch 0 0);
    animation: typing 1s steps(4) infinite;
}

.error-background {
    background: red !important;
}

.invite-link-input {
    background: #ebebeb !important;
}
.invite-link-custom-padding {
    padding: 0.5rem 2rem 0.5rem 1rem !important;
}
.custom-name-border-radius {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.custom-domain-border-radius {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

@keyframes typing {
    to {
        clip-path: inset(0 -1ch 0 0);
    }
}

.custom-badge-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.custom-button-style {
    border: none;
    background: #9ca69c;
    padding: 0.6rem 1rem;
    color: #fff;
    font-size: 13px;
    margin-left: 1rem;
    text-transform: uppercase;
    font-family: "Poppins", "Roboto", "Helvetica", "Arial", sans-serif;
    border-radius: 8px;
    font-weight: 700;
}

.validation-pool-spinner-section {
    width: 100%;
    height: 83vh;
    position: absolute;
    z-index: 111;
    background: #ffffff99;
    display: flex;
    justify-content: center;
    align-items: center;
}

.secondary-color {
    background-color: #ccc;
}
.success-color {
    background-color: rgb(23, 236, 16);
}
.scale-animate {
    animation: scaleAnimation 500ms infinite alternate;
}
@keyframes scaleAnimation {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.3);
    }
}

.animated-card {
    opacity: 0;
    width: 100%;
    transition: opacity 0.5s ease-in-out;
}

.fade-in-right {
    opacity: 1;
    animation: fadeInRight 0.5s ease-in-out;
}

.fade-in-left {
    opacity: 1;
    animation: fadeInLeft 0.5s ease-in-out;
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.survey-button {
    position: fixed;
    right: -2%;
    top: 50%;
    z-index: 11;
    border-bottom: none;
    transform: rotate(-90deg);
    padding: 0 1rem;
    border: none;
    color: #fff;
    border-radius: 12px;
    background-color: #004a7f;
    -webkit-animation: glowing 1500ms infinite;
    -moz-animation: glowing 1500ms infinite;
    -o-animation: glowing 1500ms infinite;
    animation: glowing 1500ms infinite;
}

.team-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
}

@-moz-keyframes glowing {
    0% {
        background-color: rgb(255, 87, 51);
        box-shadow: 0 0 3px rgb(255, 87, 51);
    }
    50% {
        background-color: rgb(255, 87, 51);
        box-shadow: 0 0 40px rgb(255, 87, 51);
    }
    100% {
        background-color: rgb(255, 87, 51);
        box-shadow: 0 0 3px rgb(255, 87, 51);
    }
}
@-webkit-keyframes glowing {
    0% {
        background-color: rgb(255, 87, 51);
        box-shadow: 0 0 3px rgb(255, 87, 51);
    }
    50% {
        background-color: rgb(255, 87, 51);
        box-shadow: 0 0 40px rgb(255, 87, 51);
    }
    100% {
        background-color: rgb(255, 87, 51);
        box-shadow: 0 0 3px rgb(255, 87, 51);
    }
}
@keyframes glowing {
    0% {
        background-color: rgb(255, 87, 51);
        box-shadow: 0 0 3px rgb(255, 87, 51);
    }
    50% {
        background-color: rgb(255, 87, 51);
        box-shadow: 0 0 40px rgb(255, 87, 51);
    }
    100% {
        background-color: rgb(255, 87, 51);
        box-shadow: 0 0 3px rgb(255, 87, 51);
    }
}

.disabled-class {
    color: #cacaca;
    pointer-events: none;
}

.navigation-icon-container {
    position: absolute;
    bottom: 0%;
    left: 40%;
    cursor: pointer;
}

.transparent-label-input {
    outline: none;
    background: transparent;
    border: none;
    font-size: 15px;
}

.element-select-container {
    justify-content: space-between;
    padding: 0.2rem 1rem;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}

.delete-button {
    border: none;
    background: red;
    padding: 0.55rem 1rem;
    color: #fff;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    font-family: "Poppins", "Roboto", "Helvetica", "Arial", sans-serif;
    background: "linear-gradient(310deg, rgb(234, 6, 6), rgb(255, 102, 124))";
}

.integration-logo-img {
    width: 30px;
    height: 30px;
    margin-right: -12px;
    border-radius: 50%;
}

.integration-container {
    padding: 1rem 2rem;
    text-align: center;
    background: #ff5733a8;
    border-radius: 10px;
    cursor: pointer;
}

.integration-container p {
    margin-top: 1rem;
    margin-bottom: 0;
    color: #fff;
}

.progress-bar-container {
    width: 100%;
    height: 7px;
    background-color: #f3f3f3;
    border-radius: 5px;
    /* margin-bottom: 10px; */
    align-self: center;
}

.progress-bar {
    height: 100%;
    border-radius: 5px;
}

.member-goal-icons {
    font-size: 1rem;
    color: rgb(134 121 121);
    margin-top: 0.1rem;
    margin-right: 0.5rem;
}

.team-member-goal-icons {
    font-size: 0.9rem;
    color: rgb(134 121 121 / 64%);
    margin-top: 0.1rem;
    margin-right: 0.3rem;
}
.anchor-link {
    color: #526463 !important;
}
.anchor-link:hover {
    text-decoration: underline !important;
}

.milestones-list {
    list-style: none;
    padding-left: 6rem;
}

.milestones-list li {
    position: relative;
    font-size: 15px;
    margin-bottom: 20px;
}

.milestones-list li::before {
    content: "";
    position: absolute;
    left: -43px;
    top: 10px;
    width: 35px;
    height: 2px;
    background-color: #dddde4;
}

.milestones-list li::after {
    content: "";
    position: absolute;
    top: -4px;
    bottom: -16px;
    left: -43px;
    width: 1px;
    background-color: #dddde4;
}

.milestones-list > li:last-child::after {
    height: 68%;
}

.custom-select-class {
    position: relative;
    padding-right: 30px; /* Adjust padding to make space for the icon */
    appearance: none; /* Hide default arrow */
    -webkit-appearance: none; /* for older versions of Chrome */
    -moz-appearance: none; /* for older versions of Firefox */
}

.custom-select-class::after {
    content: ">"; /* Create a pseudo-element */
    position: absolute;
    top: 50%;
    right: 10px; /* Adjust the distance of the icon from the right */
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #495057; /* Adjust color and size as needed */
    transform: translateY(-50%);
}

a:not([href]):not([tabindex]) {
    color: none !important;
    text-decoration: none;
}

.records-list::-webkit-scrollbar {
    display: none;
}

.summary-text-display {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
}
